import { DEVICE_TYPES } from './constants';

/**
 * Determine the mobile operating system.
 * This function returns one of 'IOS', 'ANDROID', 'OTHER'
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return DEVICE_TYPES.OTHER;
  }

  if (/android/i.test(userAgent)) {
    return DEVICE_TYPES.ANDROID;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return DEVICE_TYPES.IOS;
  }

  return DEVICE_TYPES.OTHER;
};
