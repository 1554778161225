import { useEffect, useState } from 'react';
import { DEVICE_TYPES } from './constants';
import { getMobileOperatingSystem } from './deviceDetection';
import { appRedirectionSchemas } from '../constants/appRedirection';
import { availableApps } from '../constants/availableApps';

const DeviceDetectionController = () => {
  const [savedRedirectionData, setSavedRedirectionData] = useState('');

  const onRedirectToAppStore = (deviceType) => {
    console.log('Redirecting to app store...', savedRedirectionData);
    if (!savedRedirectionData) return;
    const currentApp = Object.keys(availableApps).find(item => savedRedirectionData.includes(item));
    const referralCode = savedRedirectionData?.split(`${currentApp}/`)?.[1];
    if (
      currentApp && 
      referralCode && 
      appRedirectionSchemas[currentApp] && 
      deviceType === DEVICE_TYPES.ANDROID
    ) {
      const redirectionLink = `${appRedirectionSchemas[currentApp]}${referralCode}`
      window.open(redirectionLink, '_self');
    } else {
      console.log(currentApp, 
        referralCode,
        appRedirectionSchemas[currentApp],
        deviceType === DEVICE_TYPES.ANDROID)
      console.log('Invalid schema', currentApp);
    }
  };

  const onAppNotInstalled = (deviceTypeParam) => {
    onRedirectToAppStore(deviceTypeParam);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const manageOSDetect = () => {
    const currentOS = getMobileOperatingSystem();
    switch (currentOS) {
      case DEVICE_TYPES.ANDROID:
        // If the app is not installed, this line will be executed
        onAppNotInstalled(currentOS);
        break;
      default:
        console.log('Web device detected!');
        break;
    }
  };

  const fullCurrentPath = `${window.location.href}`;

  useEffect(() => {
    // Save the path data before any redirection
    if (savedRedirectionData === '' && fullCurrentPath) {
      setSavedRedirectionData(fullCurrentPath);
    }
  }, [savedRedirectionData, fullCurrentPath]);

  useEffect(() => {
    if (savedRedirectionData !== '' && Object?.keys(availableApps)?.some(item => savedRedirectionData?.includes(item))) {
      manageOSDetect();
    }
  }, [savedRedirectionData, manageOSDetect]);

  return null;
};

export default DeviceDetectionController;
